<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>钻石列表</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @change="fetchs"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()"> 搜索 </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:milky="scope">
        <span style="margin-left: 10px;">{{ scope.row.milky == false ? "否" : "是" }}</span>
      </template>
      <template v-slot:Status="scope">
        <span :style="scope.row.Status == 1 ? 'margin-left: 10px;color: red;' : 'margin-left: 10px;color: green;'">{{
          scope.row.Status == 1 ? "下架" : "上架" }}</span>
      </template>


      <template v-slot:imgSrc="scope">
        <el-image style="height: 30px; width: 30px;" lazy
          :src="(scope.row.imgSrc || '') == '' ? '' : apiUrl + scope.row.imgSrc.split('-')[0]"
          :preview-src-list="[(scope.row.imgSrc || '') == '' ? '' : apiUrl + scope.row.imgSrc.split('-')[0],]">
          <template #error>
            <div> <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /> </div>
          </template>
        </el-image>
      </template>

      <template v-slot:videoSrc="scope">
        <div v-html="(scope.row.videoSrc || '') == '' ? '' : scope.row.videoSrc"> </div>
      </template>
    </static-table>
  </el-card>
</template>
<script>
import { fetchDiamondInfos } from "@/api/diamond";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "stockIndex",
  components: { StaticTable },
  data() {
    return {
      apiUrl: 'https://www.shyblzs.com/',
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "outDataID", label: "编码", sortable: true, searchable: true, width: "110", },
          { prop: "certNumber", label: "证书号", sortable: true, searchable: true, width: "110", },
          { prop: "certType", label: "证书类型", sortable: true, searchable: true, width: "110", },
          { type: "template", prop: "Status", label: "状态", sortable: true, width: "110", },
          { prop: "shape", label: "形状", sortable: true, searchable: true, width: "110", },
          { prop: "weight", label: "重量", sortable: true, searchable: true, width: "110", },
          { prop: "color", label: "颜色", sortable: true, searchable: true, width: "110", },
          { prop: "clarity", label: "净度", sortable: true, searchable: true, width: "110", },
          { prop: "cut", label: "切工", sortable: true, searchable: true, width: "110", },
          { prop: "polish", label: "抛光", sortable: true, searchable: true, width: "110", },
          { prop: "symmetry", label: "对称", sortable: true, searchable: true, width: "110", },
          { prop: "fluorescence", label: "荧光", sortable: true, searchable: true, width: "110", },
          { type: "template", prop: "imgSrc", label: "图片", sortable: true, searchable: true, width: "110", },

          { type: "template", prop: "videoSrc", label: "视频", sortable: true, searchable: true, width: "250", },
          { prop: "globalPrice", label: "国际报价", sortable: true, searchable: true, width: "110", },
          { prop: "sellPrice", label: "销售价", sortable: true, searchable: true, width: "110", },
          { prop: "discount", label: "折扣", sortable: true, searchable: true, width: "110", },
          { prop: "totalPrice", label: "折后价", sortable: true, searchable: true, width: "110", },

          { prop: "intensity", label: "色调", sortable: true, searchable: true, width: "110", },
          { type: "template", prop: "milky", label: "是否有奶咖", sortable: true, searchable: true, width: "110", },
          { prop: "diameter", label: "直径", sortable: true, searchable: true, width: "110", },
          { prop: "tableRatio", label: "台宽比", sortable: true, searchable: true, width: "110", },
          { prop: "depthRatio", label: "全深比", sortable: true, searchable: true, width: "110", },

          { prop: "location", label: "原产地", sortable: true, searchable: true, width: "110", },
          { prop: "levelClarity", label: "净度", sortable: true, searchable: true, width: "110", },
          { prop: "eyeClean", label: "肉眼净度", sortable: true, searchable: true, width: "110", },
          { prop: "createDate", label: "创建时间", width: "180" },
        ],
      },
      pager: {
        sidx: "createDate", //默认的排序字段
        sord: "descending",
      },
      //multipleSelection: [], //多选的列
      currRow: null,

      forms: {},
      labelWidth: "90px",
      rules: {
      },
      resetDialogVisible: false,

      departmentID: '',
      departments: [],
    };
  },
  mounted() {
    let that = this;
    that.fetchs();

  },
  methods: {
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },

    fetchs() {
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      search.filters = [];
      fetchDiamondInfos(search).then((res) => {
        let dd = res.data;
        if (dd.data.length > 0) {
          for (let i = 0; i < dd.data.length; i++) {
            if ((dd.data[i].videoSrc || '') == '') {
              continue;
            }
            //处理视频显示
            dd.data[i].videoSrc = "<video   controls  width='200' height='80'><source  src=" + this.apiUrl + dd.data[i].videoSrc + "  type='video/mp4'></video>"
          }
        }
        this.pager = { ...dd };
      });
    },


  },
};
</script>
<style scoped>
.el-radio {
  margin-right: 10px;
}
</style>