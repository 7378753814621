import request from '@/utils/request'
import config from '../../package.json'



export function fetchDiamondInfos(params) {
  return request({
    url: '/DiamondInfo/Search',
    method: 'post',
    data: params
  });
}
